<template>
  <div class="page_404">
     <div class="page_404-content">
        <c-image iUrl="icon-404" iClass="icon" iWidth="260" iHeight="98"></c-image>
        <h3>Error，页面找不到啦！请点击返回首页~</h3>
        <c-button bClass="btn" @btnClick="onPageToHome">返回首页</c-button>
     </div>
  </div>
</template>

<script>
import CImage from "../components/CImage";
import CButton from "../components/form/CButton.vue";
export default {
  components: {
    [CImage.name]: CImage,
    [CButton.name]: CButton,
  },
  methods:{
    onPageToHome(){
      this.$router.replace({name:'home'})
    }
  }
};
</script>

<style>
.page_404-content{padding-top:47px;}
.page_404-content .icon{margin: 70px auto 50px;}
.page_404-content h3{font-size: 16px; color: #2E2E30; text-align: center; font-weight: normal;}
.page_404-content .btn{width:148px; margin: 20px auto 0; height: 36px; line-height: 36px; font-size: 14px;}
</style>